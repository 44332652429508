var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pb-5"},[_c('b-navbar',{attrs:{"toggleable":"lg","type":"dark"}},[_c('div',{staticClass:"container"},[_c('router-link',{attrs:{"to":"/"}},[_c('img',{attrs:{"src":"/img/logo_bike.svg","height":"40"}})])],1)]),_c('div',{staticClass:"container pt-5"},[_c('div',{staticClass:"row text-left"},[_vm._m(0),_c('div',{staticClass:"col-lg-6"},[_c('div',[_c('b-form-group',{attrs:{"label":" Escolha o tipo de conta"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ariaDescribedby = ref.ariaDescribedby;
return [_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('b-form-radio',{attrs:{"aria-describedby":ariaDescribedby,"name":"some-radios","value":true},model:{value:(_vm.pessoaJuridica),callback:function ($$v) {_vm.pessoaJuridica=$$v},expression:"pessoaJuridica"}},[_vm._v("Pessoa Jurídica")])],1),_c('div',{staticClass:"col-6"},[_c('b-form-radio',{attrs:{"aria-describedby":ariaDescribedby,"name":"some-radios","value":false},model:{value:(_vm.pessoaJuridica),callback:function ($$v) {_vm.pessoaJuridica=$$v},expression:"pessoaJuridica"}},[_vm._v("Pessoa física")])],1)])]}}])})],1),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.criarConta)}}},[_c('div',{staticClass:"row"},[_c('div',{class:_vm.pessoaJuridica ? 'col-6' : 'col-12'},[(_vm.pessoaJuridica)?_c('div',{staticClass:"form-group"},[(_vm.pessoaJuridica)?_c('label',[_vm._v("Nome fantasia")]):_c('label',[_vm._v("Nome completo")]),_c('ValidationProvider',{attrs:{"name":"Nome fantasia","rules":"required|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.novaConta.nomeFantasia),expression:"novaConta.nomeFantasia"}],staticClass:"form-control",class:classes,attrs:{"type":"text"},domProps:{"value":(_vm.novaConta.nomeFantasia)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.novaConta, "nomeFantasia", $event.target.value)}}}),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Nome completo")]),_c('ValidationProvider',{attrs:{"name":"Nome completo","rules":"required|nome_completo"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.novaConta.nomeFantasia),expression:"novaConta.nomeFantasia"}],staticClass:"form-control",class:classes,attrs:{"type":"text"},domProps:{"value":(_vm.novaConta.nomeFantasia)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.novaConta, "nomeFantasia", $event.target.value)}}}),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),(_vm.pessoaJuridica)?_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Razão Social")]),_c('ValidationProvider',{attrs:{"name":"Razão social","rules":"required|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.novaConta.razaoSocial),expression:"novaConta.razaoSocial"}],staticClass:"form-control",class:classes,attrs:{"type":"text"},domProps:{"value":(_vm.novaConta.razaoSocial)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.novaConta, "razaoSocial", $event.target.value)}}}),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]):_vm._e(),_c('div',{staticClass:"col-6"},[(_vm.pessoaJuridica)?_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("CNPJ")]),_c('ValidationProvider',{attrs:{"name":"CNPJ","rules":"required|cnpj"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.novaConta.documento),expression:"novaConta.documento"},{name:"mask",rawName:"v-mask",value:('##.###.###/####-##'),expression:"'##.###.###/####-##'"}],staticClass:"form-control",class:classes,attrs:{"type":"text","placeholder":"00.000.000/0000-00"},domProps:{"value":(_vm.novaConta.documento)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.novaConta, "documento", $event.target.value)}}}),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("CPF")]),_c('ValidationProvider',{attrs:{"name":"CPF","rules":"required|cpf"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.novaConta.documento),expression:"novaConta.documento"},{name:"mask",rawName:"v-mask",value:('###.###.###-##'),expression:"'###.###.###-##'"}],staticClass:"form-control",class:classes,attrs:{"type":"text","placeholder":"000.000.000-00"},domProps:{"value":(_vm.novaConta.documento)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.novaConta, "documento", $event.target.value)}}}),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Telefone")]),_c('ValidationProvider',{attrs:{"name":"Telefone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.novaConta.telefone),expression:"novaConta.telefone"},{name:"mask",rawName:"v-mask",value:('(##) #####-####'),expression:"'(##) #####-####'"}],staticClass:"form-control",class:classes,attrs:{"type":"text","placeholder":"(11) 12345-1234"},domProps:{"value":(_vm.novaConta.telefone)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.novaConta, "telefone", $event.target.value)}}}),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("UF")]),_c('ValidationProvider',{attrs:{"name":"UF","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.novaConta.uf),expression:"novaConta.uf"}],staticClass:"form-control",class:classes,on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.novaConta, "uf", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},_vm.listarCidades]}},[_c('option',{attrs:{"selected":""},domProps:{"value":undefined}},[_vm._v(" Selecione... ")]),_vm._l((_vm.estados),function(item){return _c('option',{key:item.id,domProps:{"value":item.id}},[_vm._v(" "+_vm._s(item.nome)+" ")])})],2),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Cidade")]),_c('ValidationProvider',{attrs:{"name":"Cidade","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.novaConta.cidade),expression:"novaConta.cidade"}],staticClass:"form-control",class:classes,attrs:{"disabled":!_vm.novaConta.uf || _vm.loadingCidade},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.novaConta, "cidade", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"selected":""},domProps:{"value":undefined}},[_vm._v(" Selecione... ")]),_vm._l((_vm.cidades),function(item){return _c('option',{key:item.id,domProps:{"value":item.id}},[_vm._v(" "+_vm._s(item.nome)+" ")])})],2),(_vm.loadingCidade)?_c('span',[_vm._v("Carregando...")]):_vm._e(),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-6"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Seu melhor e-mail")]),_c('ValidationProvider',{attrs:{"name":"e-mail","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.novaConta.email),expression:"novaConta.email"}],staticClass:"form-control",class:classes,attrs:{"type":"email","autocomplete":"nope"},domProps:{"value":(_vm.novaConta.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.novaConta, "email", $event.target.value)}}}),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"col-sm-6"},[_c('div',{staticClass:"form-group"},[_c('ValidationProvider',{attrs:{"name":"confirmar e-mail","rules":"required|confirmed:e-mail"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('label',[_vm._v("Confirme seu e-mail")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.novaConta.confirmarEmail),expression:"novaConta.confirmarEmail"}],staticClass:"form-control",class:classes,attrs:{"type":"email","autocomplete":"nope"},domProps:{"value":(_vm.novaConta.confirmarEmail)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.novaConta, "confirmarEmail", $event.target.value)}}}),(errors.length > 0)?_c('span',{staticClass:"invalid-feedback"},[_vm._v("A confirmação do e-mail deve ser igual")]):_vm._e()]}}],null,true)})],1)])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-6"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Sua nova senha")]),_c('ValidationProvider',{attrs:{"name":"senha","rules":"required|min:6"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.novaConta.senha),expression:"novaConta.senha"}],staticClass:"form-control",class:classes,attrs:{"type":"password","autocomplete":"new-password"},domProps:{"value":(_vm.novaConta.senha)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.novaConta, "senha", $event.target.value)}}}),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"col-sm-6"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Confirme sua senha")]),_c('ValidationProvider',{attrs:{"name":"confirmar senha","rules":"required|confirmed:senha"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.novaConta.confirmarSenha),expression:"novaConta.confirmarSenha"}],staticClass:"form-control",class:classes,attrs:{"type":"password","autocomplete":"nope"},domProps:{"value":(_vm.novaConta.confirmarSenha)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.novaConta, "confirmarSenha", $event.target.value)}}}),(errors.length > 0)?_c('span',{staticClass:"invalid-feedback"},[_vm._v("A confirmação da senha deve ser igual")]):_vm._e()]}}],null,true)})],1)])]),_c('button',{staticClass:"btn btn-success btn-block btn-lg",attrs:{"type":"submit","disabled":_vm.loadingCriarConta}},[(_vm.loadingCriarConta)?_c('span',[_vm._v("Criando sua conta...")]):_c('span',[_vm._v("Criar conta agora! Grátis!")])]),_c('div',{staticClass:"text-center my-2"},[_c('small',[_vm._v(" Ao clicar no botão acima, eu aceito os "),_c('a',{attrs:{"href":_vm.config.URL_TERMOS_USO,"target":"_blank"}},[_vm._v("Termo de Uso")]),_vm._v(". ")])])])]}}])}),_c('div',{staticClass:"text-center"},[_c('hr'),_c('strong',[_vm._v("Já tem uma conta?")]),_c('router-link',{staticClass:"btn btn-link p-1",attrs:{"to":"/parceiro"}},[_vm._v("Entre com o seu login")])],1)],1)])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-lg-6 mb-3 align-self-center2"},[_c('h2',{staticClass:"font-weight-normal"},[_vm._v(" Crie agora sua conta de Parceiro Bike Registrada! ")]),_c('div',{staticClass:"font-weight-bold my-3"},[_vm._v(" Sua conta é a porta de entrada para todos os serviços da nossa plataforma: ")]),_c('p',[_c('i',{staticClass:"fas fa-check text-success mr-2"}),_vm._v("Classificados de compra e venda de bicicletas Bike Registrada. ")]),_c('p',[_c('i',{staticClass:"fas fa-check text-success mr-2"}),_vm._v("Seguro para bicicletas Bike Registrada; ")]),_c('p',[_c('i',{staticClass:"fas fa-check text-success mr-2"}),_vm._v("Selo de segurança Bike Registrada; ")])])}]

export { render, staticRenderFns }