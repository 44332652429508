<template>
  <div class="pb-5">
    <b-navbar toggleable="lg" type="dark">
      <div class="container">
        <router-link to="/">
          <img src="/img/logo_bike.svg" height="40" />
        </router-link>
      </div>
    </b-navbar>

    <div class="container pt-5">
      <div class="row text-left">
        <div class="col-lg-6 mb-3 align-self-center2">
          <h2 class="font-weight-normal">
            Crie agora sua conta de Parceiro Bike Registrada!
          </h2>
          <div class="font-weight-bold my-3">
            Sua conta é a porta de entrada para todos os serviços da nossa
            plataforma:
          </div>
          <p>
            <i class="fas fa-check text-success mr-2"></i>Classificados de
            compra e venda de bicicletas Bike Registrada.
          </p>
          <p>
            <i class="fas fa-check text-success mr-2"></i>Seguro para bicicletas
            Bike Registrada;
          </p>
          <p>
            <i class="fas fa-check text-success mr-2"></i>Selo de segurança Bike
            Registrada;
          </p>
        </div>

        <div class="col-lg-6">
          <div>
            <b-form-group
              label=" Escolha o tipo de conta"
              v-slot="{ ariaDescribedby }"
            >
              <div class="row">
                <div class="col-6">
                  <b-form-radio
                    v-model="pessoaJuridica"
                    :aria-describedby="ariaDescribedby"
                    name="some-radios"
                    :value="true"
                    >Pessoa Jurídica</b-form-radio
                  >
                </div>
                <div class="col-6">
                  <b-form-radio
                    v-model="pessoaJuridica"
                    :aria-describedby="ariaDescribedby"
                    name="some-radios"
                    :value="false"
                    >Pessoa física</b-form-radio
                  >
                </div>
              </div>
            </b-form-group>
          </div>
          <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(criarConta)">
              <div class="row">
                <div :class="pessoaJuridica ? 'col-6' : 'col-12'">
                  <div class="form-group" v-if="pessoaJuridica">
                    <label v-if="pessoaJuridica">Nome fantasia</label>
                    <label v-else>Nome completo</label>
                    <ValidationProvider
                      name="Nome fantasia"
                      rules="required|min:3"
                      v-slot="{ errors, classes }"
                    >
                      <input
                        type="text"
                        class="form-control"
                        v-model="novaConta.nomeFantasia"
                        :class="classes"
                      />
                      <span class="invalid-feedback">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>

                  <div class="form-group" v-else>
                    <label>Nome completo</label>
                    <ValidationProvider
                      name="Nome completo"
                      rules="required|nome_completo"
                      v-slot="{ errors, classes }"
                    >
                      <input
                        type="text"
                        class="form-control"
                        v-model="novaConta.nomeFantasia"
                        :class="classes"
                      />
                      <span class="invalid-feedback">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="col-6" v-if="pessoaJuridica">
                  <div class="form-group">
                    <label>Razão Social</label>
                    <ValidationProvider
                      name="Razão social"
                      rules="required|min:3"
                      v-slot="{ errors, classes }"
                    >
                      <input
                        type="text"
                        class="form-control"
                        v-model="novaConta.razaoSocial"
                        :class="classes"
                      />
                      <span class="invalid-feedback">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="col-6">
                  <div v-if="pessoaJuridica" class="form-group">
                    <label>CNPJ</label>
                    <ValidationProvider
                      name="CNPJ"
                      rules="required|cnpj"
                      v-slot="{ errors, classes }"
                    >
                      <input
                        type="text"
                        class="form-control"
                        v-model="novaConta.documento"
                        :class="classes"
                        v-mask="'##.###.###/####-##'"
                        placeholder="00.000.000/0000-00"
                      />
                      <span class="invalid-feedback">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <div v-else class="form-group">
                    <label>CPF</label>
                    <ValidationProvider
                      name="CPF"
                      rules="required|cpf"
                      v-slot="{ errors, classes }"
                    >
                      <input
                        type="text"
                        class="form-control"
                        v-model="novaConta.documento"
                        :class="classes"
                        v-mask="'###.###.###-##'"
                        placeholder="000.000.000-00"
                      />
                      <span class="invalid-feedback">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <label>Telefone</label>
                    <ValidationProvider
                      name="Telefone"
                      rules="required"
                      v-slot="{ errors, classes }"
                    >
                      <input
                        type="text"
                        class="form-control"
                        v-model="novaConta.telefone"
                        :class="classes"
                        v-mask="'(##) #####-####'"
                        placeholder="(11) 12345-1234"
                      />
                      <span class="invalid-feedback">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <label>UF</label>
                    <ValidationProvider
                      name="UF"
                      rules="required"
                      v-slot="{ errors, classes }"
                    >
                      <select
                        @change="listarCidades"
                        class="form-control"
                        v-model="novaConta.uf"
                        :class="classes"
                      >
                        <option :value="undefined" selected>
                          Selecione...
                        </option>
                        <option
                          v-for="item in estados"
                          :key="item.id"
                          :value="item.id"
                        >
                          {{ item.nome }}
                        </option>
                      </select>
                      <span class="invalid-feedback">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <label>Cidade</label>
                    <ValidationProvider
                      name="Cidade"
                      rules="required"
                      v-slot="{ errors, classes }"
                    >
                      <select
                        class="form-control"
                        v-model="novaConta.cidade"
                        :class="classes"
                        :disabled="!novaConta.uf || loadingCidade"
                      >
                        <option :value="undefined" selected>
                          Selecione...
                        </option>
                        <option
                          v-for="item in cidades"
                          :key="item.id"
                          :value="item.id"
                        >
                          {{ item.nome }}
                        </option>
                      </select>
                      <span v-if="loadingCidade">Carregando...</span>
                      <span class="invalid-feedback">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-sm-6">
                  <div class="form-group">
                    <label>Seu melhor e-mail</label>
                    <ValidationProvider
                      name="e-mail"
                      rules="required|email"
                      v-slot="{ errors, classes }"
                    >
                      <input
                        type="email"
                        class="form-control"
                        :class="classes"
                        v-model="novaConta.email"
                        autocomplete="nope"
                      />
                      <span class="invalid-feedback">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <ValidationProvider
                      name="confirmar e-mail"
                      rules="required|confirmed:e-mail"
                      v-slot="{ errors, classes }"
                    >
                      <label>Confirme seu e-mail</label>
                      <input
                        type="email"
                        class="form-control"
                        :class="classes"
                        v-model="novaConta.confirmarEmail"
                        autocomplete="nope"
                      />
                      <span class="invalid-feedback" v-if="errors.length > 0"
                        >A confirmação do e-mail deve ser igual</span
                      >
                    </ValidationProvider>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-6">
                  <div class="form-group">
                    <label>Sua nova senha</label>
                    <ValidationProvider
                      name="senha"
                      rules="required|min:6"
                      v-slot="{ errors, classes }"
                    >
                      <input
                        type="password"
                        class="form-control"
                        :class="classes"
                        v-model="novaConta.senha"
                        autocomplete="new-password"
                      />
                      <span class="invalid-feedback">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label>Confirme sua senha</label>
                    <ValidationProvider
                      name="confirmar senha"
                      rules="required|confirmed:senha"
                      v-slot="{ errors, classes }"
                    >
                      <input
                        type="password"
                        class="form-control"
                        :class="classes"
                        v-model="novaConta.confirmarSenha"
                        autocomplete="nope"
                      />
                      <span class="invalid-feedback" v-if="errors.length > 0"
                        >A confirmação da senha deve ser igual</span
                      >
                    </ValidationProvider>
                  </div>
                </div>
              </div>

              <button
                class="btn btn-success btn-block btn-lg"
                type="submit"
                :disabled="loadingCriarConta"
              >
                <span v-if="loadingCriarConta">Criando sua conta...</span>
                <span v-else>Criar conta agora! Grátis!</span>
              </button>

              <div class="text-center my-2">
                <small>
                  Ao clicar no botão acima, eu aceito os
                  <a :href="config.URL_TERMOS_USO" target="_blank"
                    >Termo de Uso</a
                  >.
                </small>
              </div>
            </form>
          </ValidationObserver>

          <div class="text-center">
            <hr />
            <strong>Já tem uma conta?</strong>

            <router-link class="btn btn-link p-1" to="/parceiro"
              >Entre com o seu login</router-link
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import config from "../../config";
import auth from "../../auth";
import captureError from "../../helpers/captureError";

export default {
  components: {},
  data() {
    return {
      config,
      novaConta: {
        nome: undefined,
        email: undefined,
      },
      estados: [
        { id: "1EAB0206-5FB6-41F1-9A29-F9D8067E4AD9", nome: "AC" },
        { id: "0D44358F-9C20-4ED4-A1FC-E15D05A31FE7", nome: "AL" },
        { id: "67D1229B-C19F-4E0D-B575-382162B6D8E0", nome: "AM" },
        { id: "866B4464-577C-4736-9536-FE0470F32C86", nome: "AP" },
        { id: "7CE3CF52-10A5-4621-8C15-FC0D34FA792D", nome: "BA" },
        { id: "19ABD2B0-A7AF-4F37-98F9-012751B1DA21", nome: "CE" },
        { id: "A3D8F878-201E-4452-AFCF-140A95C3D716", nome: "DF" },
        { id: "5A0C4B7D-8A44-4115-BBC7-1CBA90B47EC9", nome: "ES" },
        { id: "A2639BEA-BEE6-4FB2-BC9C-9BF2C66A9233", nome: "GO" },
        { id: "283AC4B2-F067-43D3-8B83-C2E8293E040A", nome: "MA" },
        { id: "8A9E609C-FB15-4A99-90F2-AB0F0E1FBE3B", nome: "MG" },
        { id: "CEEE13CE-3022-4AF1-AED8-61A9356642D6", nome: "MS" },
        { id: "CE93DD82-52F5-4A51-8950-1D6087231E37", nome: "MT" },
        { id: "FF5EF1C2-6C9C-4935-A5E5-7B044A002985", nome: "PA" },
        { id: "422B0F8A-6837-4D91-B5D4-6FC79D1C9886", nome: "PB" },
        { id: "87C377CF-1F2B-4125-861C-AEE0FAD07DC1", nome: "PE" },
        { id: "CDAB5287-374F-4165-8550-E1CA220076A9", nome: "PI" },
        { id: "9D78CDF5-69E2-4DB2-A607-F141C188476A", nome: "PR" },
        { id: "1F6797E1-A1E2-4374-BF9E-C8C3589B6526", nome: "RJ" },
        { id: "CD057732-101F-4C23-997D-1D77F0EADD52", nome: "RN" },
        { id: "85A78139-E0B8-44E6-A229-2C7FA4766DDF", nome: "RO" },
        { id: "AB87E4F3-4DA8-4312-B564-F574319AC80C", nome: "RR" },
        { id: "2562B9DB-F15A-4F2E-AED3-2A7CC230F0DF", nome: "RS" },
        { id: "71CAF1D9-2176-4423-98F5-5FCABA931C21", nome: "SC" },
        { id: "B1A811FB-6484-49D3-BC8E-308A13DAFA48", nome: "SE" },
        { id: "BC71E35D-17BC-427F-B8E8-31DA8A16AF3E", nome: "SP" },
        { id: "91CB1201-0481-4791-8FC1-26C1A788FEB8", nome: "TO" },
      ],
      estado: undefined,
      cidade: undefined,
      cidades: [],
      loadingCriarConta: false,
      loadingCidade: false,
      pessoaJuridica: true,
    };
  },

  methods: {
    async criarConta() {
      try {
        this.loadingCriarConta = true;

        this.novaConta.urlCadastro = window.location.href;

        var response = await axios.post(
          `${config.API_URLV2}/Lojista/Criarconta`,
          this.novaConta
        );

        auth.setUserInfo(response.data);

        //Redireciona para a home
        this.$router.replace("/dashboard/");
      } catch (e) {
        this.loadingCriarConta = false;
        captureError(e);
        Swal.fire({
          text: e.response.data.Message
            ? e.response.data.Message
            : "Não foi possivel efetuar o cadastro",
          icon: "error",
          confirmButtonText: "Ok",
        });
      }
      this.loadingCriarConta = false;
    },
    async listarCidades() {
      try {
        this.loadingCidade = true;

        this.novaConta.cidade = undefined;
  
        var response = await axios.get(
          `${config.API_URLV2}/cidade/${this.novaConta.uf}`
        );

        this.cidades = response.data;

        this.loadingCidade = false;
      } catch (e) {
        captureError(e);
        Swal.fire({
          text: "Não foi possível encontrar as cidades",
          icon: "error",
          confirmButtonText: "Ok",
        });
        this.novaConta.uf = undefined;
      }

      this.loadingCidade = false;
    },

    contaJaExiste() {
      var ehcnpj = this.pessoaJuridica ? "CNPJ" : "CPF";
      Swal.fire({
        title: "Oops...",
        html: `Já existe um conta cadastrada com este ${ehcnpj}. Caso a conta seja sua, deseja receber por e-mail as instruções para recuperar a senha?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sim, receber instruções",
        cancelButtonText: "Não",
        allowOutsideClick: () => !Swal.isLoading(),
        preConfirm: async () => {
          Swal.showLoading();
          await this.recuperarSenha();
        },
      });
    },
    async recuperarSenha() {
      var email = this.novaConta.email;

      if (!email) {
        return;
      }

      try {
        await axios.post(
          `${config.API_URLV2}/auth/esqueciSenha?email=${email}`,
          {}
        );

        await Swal.fire({
          icon: "success",
          title: "E-mail de instruções enviado com sucesso.",
        });

        this.$router.replace("/login");
      } catch (e) {
        this.loading = false;
        captureError(e);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text:
            "Não foi possível enviar o e-mail. Tente novamente mais tarde ou contate o suporte.",
        });
      }
    },
  },
};
</script>

<style scoped>
.form-group label {
  font-weight: bold;
  font-size: 0.9rem;
}
</style>
